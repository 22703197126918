<template>
  <form @submit.prevent="save">
    <!-- Form for adding a new record -->
    <div class="box">
      <h1 class="is-title is-size-3">{{ header }}</h1>
      <p class="is-text">{{locationString}}</p>
      <div class="columns">
        <!-- Text fields -->
        <div class="column is-half">
          <div class="field">
            <div class="label is-pulled-left is-text">Object ID</div>
            <div class="control">
              <b-input
              ref="obj"
              :id="'obj'"
              :placeholder="'Object ID reference'"
              :maxLength="20"
              :isRequired="true"
            ></b-input>
            </div>
          </div>
          <div class="field">
            <div class="label is-pulled-left is-text">Title</div>
            <div class="control">
              <b-input
              ref="title"
              :id="'title'"
              :placeholder="'Title of record'"
              :maxLength="60"
              :isRequired="true"
            ></b-input>
            </div>
          </div>
          <div class="field">
            <div class="label is-pulled-left is-text">Description</div>
            <div class="control">
              <b-text-area
              ref="desc"
              :id="'desc'"
              :placeholder="'Description for record'"
              :maxLength="300"
              :isRequired="true"
              ></b-text-area>
            </div>
          </div>
          <div class="field">
            <div class="label is-pulled-left is-text">Photographer</div>
            <div class="control">
              <b-input
              ref="photo"
              :id="'photo'"
              :placeholder="'Photographer Name'"
              :maxLength="50"
              :isRequired="true"
            ></b-input>
            </div>
          </div>
          <div class="field">
            <div class="label is-pulled-left is-text">Credit</div>
            <div class="control">
              <b-input
              ref="credit"
              :id="'credit'"
              :placeholder="'Photo credit'"
              :maxLength="100"
              :isRequired="true"
            ></b-input>
            </div>
          </div>
          <div class="field">
            <div>
              <div class="label is-pulled-left is-text">Date</div>
              <Tooltip
              class="is-pulled-left ml-2"
              style="position:relative; z-index:2"
                :text="dateInfoText"
              ></Tooltip>
            </div>
            <div class="control">
              <b-input
              ref="date"
              :id="'date'"
              :placeholder="'Date of photograph'"
              :maxLength="30"
              :isRequired="true"
            ></b-input>
            </div>
          </div>
          <div class="field">
            <div class="label is-pulled-left is-text">Purchase Information</div>
            <div class="control">
              <b-input
              ref="img_desc"
              :id="'img_desc'"
              :placeholder="'Buy image here . . .'"
              :maxLength="100"
              :isRequired="true"
            ></b-input>
            </div>
          </div>
        </div>

        <!-- Media uploads -->
        <div class="column is-half">
          <image-upload-form
            ref="imgUpload"
            :header="'1. Image Upload - For Table'"
            :instructions="'JPG or PNG image, 960 x 740 pixels, under 10 MB'"
            :existingImgPath="record.image"
            @choseFile="(file) => createThumbnail(file)"
          />
          <image-upload-form
            ref="thmbUpload"
            :header="'2. Thumbnail Upload - For Mobile'"
            :instructions="'JPG or PNG image, '+thmbSize+' pixels tall, under 10 MB'"
            :existingImgPath="record.thumbnail"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="buttons">
            <button
              class="button is-primary is-pulled-right is-text"
              style="text-decoration: none"
            >
              {{ submitText }}
            </button>
            <button
              class="button is-danger is-text"
              style="text-decoration: none"
              type="button"
              v-on:click="closeForm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ImageUploadForm from "../../components/ImageUploadForm.vue";
import BInput from "../../components/b-input.vue";
import BTextArea from '../../components/b-textArea.vue';
import Tooltip from '../../components/Tooltip.vue';
export default {
  props: {
    collection_id: {
      type: String,
      required: false,
    },
    location_id: {
      type: Number,
      required: true,
    },
    isEditForm: {
      type: Boolean,
      default: false,
      required: true,
    },
    recordToEdit: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      record: {},
      location:{},
      isEditing: false,
      col_id: -1,
      thmbSize: 512
    };
  },
  components: { ImageUploadForm, BInput, BTextArea, Tooltip },
  async mounted() {
    this.location = await this.$store.dispatch(
        "locations/getLocationFromId",
        this.location_id
      );

    if (this.collection_id == null) {
        this.col_id = this.location.collection_id;
      } else {
        this.col_id = this.collection_id;
      }

    if (this.recordToEdit != null) {
      this.UpdateRecordAndForm();
    }

    
  },
  watch: {
    recordToEdit() {
      if (this.recordToEdit != null) {
        this.UpdateRecordAndForm();
      }
    },
  },
  computed: {
    header() {
      if (this.isEditForm){
        return "Edit Record: " + this.record.title;
      }
      else return "Add New Record";
    },
    locationString(){
      if(this.location){
        if(this.location.address != "" && this.location.address != null){
          return this.location.address;
        }
        else{
          return this.location.latitude + ", "+ this.location.longitude;
        }
      }
      else return ""
    },
    submitText() {
      if (this.isEditForm) return "Save";
      else return "Add New";
    },
    dateInfoText(){
      return "Records are ordered by date. Format should be one of the following:\n"
      + "Year/Month/Day\n"
      + "Year/Month\n"
      + "Year(s)\n"
      + "Year circa\n"
      + "Year(s) early\n"
      + "Undated";
    }
  },

  methods: {
    UpdateRecordAndForm() {
      this.record = this.recordToEdit;
      this.$refs.obj.setValue(this.record.object_id);
      this.$refs.title.setValue(this.record.title);
      this.$refs.desc.setValue(this.record.description);
      this.$refs.photo.setValue(this.record.photographer);
      this.$refs.credit.setValue(this.record.credit);
      this.$refs.date.setValue(this.record.date);
      this.$refs.img_desc.setValue(this.record.image_description);
    },
    async UploadImages() {
      //console.log("starting upload");
      await this.$refs.imgUpload.submitFile().then((res) => {
        //console.log("finished img: ", res);
        if (res != "") this.record.image = res;
      });

      await this.$refs.thmbUpload.submitFile().then((res) => {
        //console.log("finished thmb: ", res);
        if (res != "") this.record.thumbnail = res;
      });

      //console.log("done");
    },
    async save() {
      this.isEditing = true;
      this.$store.commit("AWAIT_RESPONSE", true);

      //set new values
      this.record.object_id = this.$refs.obj.getValue();
      this.record.title = this.$refs.title.getValue();
      this.record.description = this.$refs.desc.getValue();
      this.record.photographer = this.$refs.photo.getValue();
      this.record.credit = this.$refs.credit.getValue();
      this.record.date = this.$refs.date.getValue();
      this.record.image_description = this.$refs.img_desc.getValue();

      if (this.isEditForm) {
        try {
          await this.UploadImages();

          //console.log("update record");
          await this.$store
            .dispatch("records/update", {
              route:
                "/collections/" +
                this.col_id +
                "/locations/" +
                this.location_id +
                "/records/" +
                this.record.id,
              record: this.record,
            })
            .then(() => {
              //console.log("finished update");
              this.$store.commit("AWAIT_RESPONSE", false);
              this.isEditing = false;

              this.closeForm();
            });
        } catch (error) {
          this.$store.dispatch("showErrorMessage", error);
          this.$store.commit("AWAIT_RESPONSE", false);
          this.isEditing = false;
        }
      } else {
        try {
          this.record.location_id = this.location_id;

          await this.UploadImages();

          //console.log("Add new record");
          //console.log(this.record);

          this.$store
            .dispatch("records/create", {
              route:
                "/collections/" +
                this.col_id +
                "/locations/" +
                this.location_id +
                "/records",
              record: this.record,
            })
            .then(() => {
              this.$store.commit("AWAIT_RESPONSE", false);
              this.isEditing = false;
              this.closeForm();
            });
        } catch (error) {
          this.$store.dispatch("showErrorMessage", error);
          this.$store.commit("AWAIT_RESPONSE", false);
          this.isEditing = false;
        }
      }
    },
    createThumbnail(imgBlob){
        try{
          const canvas = document.createElement("canvas");
          const img = document.createElement("img");

          img.onload = () => {
            let ctx = canvas.getContext("2d");
            canvas.width = (img.width * this.thmbSize) / img.height;
            canvas.height = this.thmbSize;

            ctx.drawImage(img, 0,0,canvas.width,canvas.height);
            var thmb = canvas.toDataURL("image/jpeg");

            this.$refs.thmbUpload.setFile(thmb);
          }

          img.src = imgBlob;
        }
        catch(error){
          this.$store.dispatch("showErrorMessage", error);
        }
    },
    closeForm() {
      this.$emit("close");
    },
  },
};
</script>