<template>
  <div class="tooltip">
    <font-awesome-icon icon="question-circle" />
    <span class="tooltiptext" style="white-space: pre-line" :class="[{'z-index': zindex}]">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String, default: "Tooltip" },
    position: { type: String, default: "bottom" },
    zindex: {type: Number, default: 1},
  },
  computed: {
    floatTextPosition() {
      const possiblePositions = ["left", "right", "top", "bottom"];
      return (
        "tooltip-" +
        (possiblePositions.find((s) => this.position === s) || "top")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  min-width:300px;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  margin-left: 5px;
  user-select: none;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
  overflow-wrap: break-word;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

// .tooltip .tooltip-left {
//   top: -5px;
//   right: 105%;
// }
// .tooltip .tooltip-right {
//   top: -5px;
//   left: 105%;
// }
// .tooltip .tooltip-top {
//   bottom: 100%;
//   left: 50%;
//   margin-left: -100px; /* Use half of the width (120/2 = 60), to center the tooltip */
// }
// .tooltip .tooltip-bottom {
//   top: 100%;
//   left: 50%;
//   margin-left: -100px; /* Use half of the width (120/2 = 60), to center the tooltip */
// }

/* Tooltip arrow (bottom) */
// .tooltip .tooltiptext::after {
//   content: "";
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -5px;
//   border-width: 5px;
//   border-style: solid;
//   border-color: #555 transparent transparent transparent;
// }


</style>