<template>
  <div class="m-3">
    <div class="message">
      <div class="message-header is-header">
        <p>{{ header }}</p>
      </div>
      <div class="message-body">
        <div class="columns">
          <!-- Image -->
          <div class="column is-one-quarter">
          <figure
            class="image
              has-background-grey
              is-flex
              is-justify-content-center
            "
            style="width:100%;aspect-ratio:1"
          >
            <font-awesome-icon
              icon="image"
              style="width:50%;height:auto;"
              v-show="file == '' && img_url == ''"
            />
            <img
              :src="imgSource"
              style="width:100%;aspect-ratio:1;object-fit: cover"
              v-show="file || img_url"
            />
          </figure>
          </div>

          <!-- Choose a file button and text -->
          <div class="control column is-three-quarters">
            <div class="file has-name is-primary is-fullwidth my-3">
              <label class="file-label is-fullwidth">
                <input
                  class="file-input"
                  type="file"
                  name="file"
                  accept=".jpg,.png,.jpeg"
                  @change="handleFileUpload($event)"
                />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label is-text has-text-white">
                    Choose a file…
                  </span>
                </span>
                <span class="file-name is-fullwidth is-text">
                  {{ fileName }}
                </span>
              </label>
            </div>

            <p class="is-pulled-left mt-3 is-text">{{ instructions }}</p>
          </div>

          <!-- <div class="control">
            <button v-on:click="submitFile()" class="is-primary button">Submit</button>
          </div> -->
        </div>
      </div>

      <br />
    </div>
  </div>
  <!-- <p id="img-uploader">Get URL</p>
    <button class="button is-primary" v-on:click="getUrl">Get Url</button> -->
</template>

<script>
import { upload_file } from "../services/s3-signed-upload";

export default {
  props: {
    header: {
      type: String,
      default: "Image Upload",
    },
    instructions: {
      type: String,
    },
    existingImgPath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      file: "",
      img_url: "",
    };
  },
  mounted() {
    this.img_url = this.existingImgPath;
  },
  watch: {
    existingImgPath() {
      this.img_url = this.existingImgPath;
      if (this.img_url == null || this.img_url == "") {
        this.img_url = "";
        this.file = "";
      }
    },
  },
  computed: {
    imgSource() {
      if (this.img_url == null) return "";

      return this.img_url;
    },
    fileName() {
      if (this.file != "" && this.file.name != "") return this.file.name;
      else if (this.img_url != "" && this.img_url != null)
        return this.img_url.replace(process.env.VUE_APP_S3_URL, "");
      else return ". . .";
    },
  },
  methods: {
    // getUrl() {
    //   console.log("getting url");
    //   this.$store.dispatch("getSignature");
    // },
    setFile(src) {
      this.srcToFile(src, "thmb.jpg", "image/jpeg").then((file) => {
        //console.log(file);
        this.file = file;
      });
      this.img_url = src;
    },
    srcToFile(src, fileName, mimeType) {
      return fetch(src)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], fileName, { type: mimeType });
        });
    },
    async handleFileUpload(e) {
      //console.log(e.target.files[0]);
      if (
        e.target.files[0].type != "image/jpeg" &&
        e.target.files[0].type != "image/png"
      ) {
        this.$store.dispatch(
          "showErrorMessage",
          "Image must be of type jpg, jpeg, or png"
        );
        return;
      } else if (e.target.files[0].size > 10000000) {
        this.$store.dispatch(
          "showErrorMessage",
          "Image is too large. Try downsizing."
        );
        return;
      }
      this.file = e.target.files[0];
      //console.log(this.file);
      this.img_url = await URL.createObjectURL(e.target.files[0]);

      this.$emit("choseFile", this.img_url);
    },
    async submitFile() {
      //console.log("submitting file");
      // return "https://s3.us-west-1.amazonaws.com/uploads.santamonicahistory.org/9k5c5ugqhpph1py60r0kow.jpg";
      if (this.file != "") {
        // const signedUrl = await GetSignedUrl(this.file);
        // console.log("got url");
        this.img_url = await upload_file(this.file);
        //console.log("img_url: ",this.img_url);
        if (this.img_url != "") {
          this.$store.commit("RAISE_SUCCESS", "Image Uploaded!");
          //console.log("returning: ", this.img_url);
          return this.img_url;
        }
      }
      return "";
    },
  },
};
</script>