//this is based on this article: https://dev.to/justinschroeder/better-uploads-with-vue-formulate-s3-and-lambda-58b8
// and uses a lambda function and api gateway to get the s3 bucket url
import axios from "axios";

//accepts a file of type jpg and returns the URL of the hosted image
export async function upload_file(file){
  var sig_req = axios.create({
    baseURL: process.env.VUE_APP_LAMBDA_UPLOAD,
    'Content-type': 'application/json'
  });
  return await sig_req.post("/signature", {
      mime: file.type,
      extension: 'jpg'
    })
    .then(response =>{
      var signedUrl = response.data.uploadUrl

      var options={
        headers: {
          'Content-Type': file.type
        }
      }
      //console.log("SIGNED URL: ",signedUrl)
      return axios.put(signedUrl, file, options)
  })
  .then(response=>{
    let img_url = response.request.responseURL.replace(/\?.*$/,"");
    //console.log("find me at " + img_url)
    // console.log("url " + response.config.url)
    return img_url
  })
  .catch(function(err){
      return err
  })

}

//calls AWS lambda function to delete object from bucket
export async function delete_file(urlToDelete){
  try{
      var req = axios.create({
        baseURL:process.env.VUE_APP_LAMBDA_DELETE,
        'Content-type': 'application/json'
      });

      return await req.post("/deleteImageFromBucket",{
        url: urlToDelete
      })
  }
  catch(err){
    return err
  }
}